import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import copy from "copy-to-clipboard";

import { cn } from "../../../utils";
import LinkIcon from "../../Icons/LinkIcon";
import LoadingIcon from "../../Icons/LoadingIcon";
import FeaturesModal from "../../White-Label/FeaturesModal";
import CloseBtn from "../../../assets/Images/close-btn.svg";

import {
  getCustomEmailDetails,
  resetCustomEmailData,
  updateCustomEmailDetails,
  veficationStatusCheck,
} from "../../../store/actions/WhiteLabel/customEmailAction";
import { useWhiteLabelSettings } from "../../../contexts/WhiteLabelProvider";
import TickIcon from "../../Icons/TickIcon";
import { customStyles } from "../../../utils/modalStyles";
import ReactTooltip from "react-tooltip";

const Chip = ({ isCustom, status }) => (
  <span
    className={cn(
      "text-Neutral000 rounded-3xl text-[10px] peer-disabled:hidden peer-focus:hidden",
      isCustom ? "bg-Green400" : "bg-Neutral900 px-2 py-0.5",
      status === "Pending" && "bg-orange-500 px-2 py-0.5"
    )}
  >
    {status ? (
      status === "Verified" ? (
        <TickIcon className="size-5" />
      ) : (
        status
      )
    ) : isCustom ? (
      <TickIcon className="size-5" />
    ) : (
      "Default"
    )}
  </span>
);

function CustomEmailCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.user);
  const customEmailData = useSelector((state) => state?.customEmail);

  const [form, setForm] = useState({
    sender_name: "",
    reply_to_mail: "",
    smtp_mail: "",
  });
  const [domainModalOpen, setDomainModalOpen] = useState();
  const [focusedInput, setFocusedInput] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReloadSite, setIsReloadSite] = useState(false);
  const [copyName, setCopyName] = useState("Copy");
  const [copyValue, setCopyValue] = useState("Copy");
  const [isError, setIsError] = useState({
    sender_name: null,
    reply_to_mail: null,
    smtp_mail: null,
  });
  const [emailVerified, setEmailVerified] = useState({
    smtp_mail: null,
    reply_to_mail: null,
  });

  const domainDetails = customEmailData?.data?.domain;

  const { settings } = useWhiteLabelSettings();
  const isWhiteLabelSubscriber = settings.get("isWhiteLabelSubscriber");
  // const isWhiteLabelSubscriber = true; // For testing purposes

  const callVerificationCheckAPI = useCallback(() => {
    if (form.smtp_mail && form.reply_to_mail) {
      const emails = [form.smtp_mail, form.reply_to_mail];
      dispatch(
        veficationStatusCheck({
          emails: emails,
          userId: user?._id,
        })
      );
    } else if (form.smtp_mail || form.reply_to_mail) {
      dispatch(
        veficationStatusCheck({
          email: form.smtp_mail
            ? [form.smtp_mail]
            : form.reply_to_mail
            ? [form.reply_to_mail]
            : null,
          userId: user?._id,
        })
      );
    }
  }, [dispatch, isReloadSite]);

  const callCustomEmailDetailsAPI = () => {
    dispatch(getCustomEmailDetails(user?._id, setIsReloadSite));
  };

  useEffect(() => {
    callCustomEmailDetailsAPI();
  }, []);

  useEffect(() => {
    setForm({
      sender_name: customEmailData?.data?.sender_name
        ? customEmailData?.data?.sender_name
        : "",
      reply_to_mail: customEmailData?.data?.reply_to_mail
        ? customEmailData?.data?.reply_to_mail
        : "",
      smtp_mail: customEmailData?.data?.smtp_mail
        ? customEmailData?.data?.smtp_mail
        : "",
    });
    setEmailVerified({
      smtp_mail: customEmailData?.data?.smtpEmailVerified?.isVerified
        ? "Verified"
        : customEmailData?.data?.smtpEmailVerified?.isMailSend
        ? "Pending"
        : "Default",
      reply_to_mail: customEmailData?.data?.replyEmailVerified?.isVerified
        ? "Verified"
        : customEmailData?.data?.replyEmailVerified?.isMailSend
        ? "Pending"
        : "Default",
    });
  }, [customEmailData?.data]);

  useEffect(() => {
    if (isReloadSite) {
      callCustomEmailDetailsAPI();
      callVerificationCheckAPI();
    }
  }, [isReloadSite]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value.toLowerCase() }));
    setIsError((prev) => ({ ...prev, [name]: "" }));
  };

  const handleInputFocus = (name) => {
    setFocusedInput(name);
  };

  const resetFocusedInput = (e, name) => {
    if (e.target.value) {
      setTimeout(() => {
        setFocusedInput("");
      }, 1500);
    } else {
      setFocusedInput("");
    }
  };

  const showUpgradeToWhiteLabelModal = () => {
    if (!isWhiteLabelSubscriber) {
      setShowUpgradeModal(true);
    }
  };

  const handleUpdateDetails = (name) => {
    try {
      let errors = {};
      const simpleEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const errMsg =
        "Invalid email format. Must include '@' and '.' without spaces";

      if (name === "sender_name" && form.sender_name.trim() === "") {
        errors.sender_name = "Sender name is required";
      }

      if (name === "reply_to_mail") {
        if (form.reply_to_mail.trim() === "") {
          errors.reply_to_mail = "Reply-to email address is required";
        } else if (form.reply_to_mail.includes("yopmail")) {
          errors.reply_to_mail = "This Reply-to email is not valid";
        } else if (!simpleEmailRegex.test(form.reply_to_mail)) {
          errors.reply_to_mail = errMsg;
        }
      }

      if (name === "smtp_mail") {
        if (form.smtp_mail.trim() === "") {
          errors.smtp_mail = "SMTP settings are required";
        } else if (form.smtp_mail && form.smtp_mail.includes("yopmail")) {
          errors.smtp_mail = "This smtp_mail email is not valid";
        } else if (!simpleEmailRegex.test(form.smtp_mail)) {
          errors.smtp_mail = errMsg;
        }
      }

      if (Object.keys(errors).length > 0) {
        setIsError(errors);
        return; // Don't proceed with the update if there are errors
      }

      const values = {
        sender_name:
          name === "sender_name"
            ? form.sender_name
            : customEmailData?.data?.sender_name,
        reply_to_mail:
          name === "reply_to_mail"
            ? form.reply_to_mail
            : customEmailData?.data?.reply_to_mail,
        smtp_mail:
          name === "smtp_mail"
            ? form.smtp_mail
            : customEmailData?.data?.smtp_mail,
        userId: user?._id,
      };
      // const domain = values.smtp_mail
      //   ? values.smtp_mail.split("@")
      //   : values.reply_to_mail
      //   ? values.reply_to_mail.split("@")
      //   : null;
      // const domainName =
      //   domain && domain[1] !== "gmail.com" && domain[1] !== "yahoo.com"
      //     ? domain[1]
      //     : null;
      dispatch(
        updateCustomEmailDetails(
          {
            ...values,
            // domainName: domainName,
          },
          setIsLoading,
          setFocusedInput,
          setIsError
        )
      );
    } catch (error) {
      console.log("Error in update custom email : ", error);
      setIsLoading(false);
    }
  };

  const handleCopy = (text, index, type) => {
    copy(text);
    if (type === "name") {
      setCopyName((prev) => ({ ...prev, [index]: "Copied" }));
      setTimeout(() => {
        setCopyName((prev) => ({ ...prev, [index]: "Copy" }));
      }, 1500);
    } else {
      setCopyValue((prev) => ({ ...prev, [index]: "Copied" }));
      setTimeout(() => {
        setCopyValue((prev) => ({ ...prev, [index]: "Copy" }));
      }, 1500);
    }
  };

  const handleReset = () => {
    if (
      customEmailData?.data?.sender_name ||
      customEmailData?.data?.reply_to_mail ||
      customEmailData?.data?.smtp_mail
    ) {
      dispatch(resetCustomEmailData({ userId: user?._id }));
    }
  };

  const renderInput = (name, placeholder, type) => (
    <>
      {isReloadSite ? (
        <div className="z-10 text-Neutral000 bg-AccentRegular hover:bg-AccentMediumDark">
          <LoadingIcon />
        </div>
      ) : (
        <div className="flex flex-grow gap-4">
          {name !== "domain_details" && (
            <>
              <div
                className={cn(
                  "relative px-3 py-2.5 gap-2 inline-flex items-center border ring-2 ring-transparent hover:ring-offset-2 rounded-md border-Neutral300 flex-grow focus-within:ring-AccentMediumLight hover:ring-AccentMediumLight",
                  !isWhiteLabelSubscriber && "hover:ring-Neutral300 py-2.5"
                )}
              >
                <input
                  type={type}
                  name={name}
                  className="w-full text-sm font-medium bg-transparent text-Neutral800 focus:outline-none placeholder:tracking-wider peer placeholder:text-Neutral500"
                  placeholder={placeholder}
                  value={form[name]}
                  onChange={handleChange}
                  onFocus={() => handleInputFocus(name)}
                  onBlur={(e) => resetFocusedInput(e, name)}
                  disabled={!isWhiteLabelSubscriber || isLoading}
                />
                {focusedInput !== name && (
                  <Chip
                    isCustom={form[name] !== "" && !isError[name]}
                    status={name !== "sender_name" && emailVerified[name]}
                  />
                )}
                {!isWhiteLabelSubscriber && (
                  <div
                    className="absolute inset-0 z-10 hidden peer-disabled:block"
                    onClick={showUpgradeToWhiteLabelModal}
                  >
                    {/* To show the upgrade modal (Do not remove) */}
                  </div>
                )}
              </div>
              {focusedInput === name && (
                <button
                  type="button"
                  onClick={() => handleUpdateDetails(name)}
                  className="z-10 px-6 text-xs font-bold rounded text-Neutral000 bg-AccentRegular hover:bg-AccentMediumDark"
                  disabled={isLoading || form[name] === ""}
                >
                  {isLoading ? <LoadingIcon /> : "Save"}
                </button>
              )}
            </>
          )}

          {/* {name === "domain_details" && (
            <div
              className={
                "relative px-3 py-2.5 gap-2 inline-flex items-center border rounded-md border-Neutral300 flex-grow bg-Neutral300"
              }
            >
              <button
                type="button"
                className="w-full text-sm font-medium text-left bg-transparent text-Neutral800 focus:outline-none placeholder:tracking-wider peer placeholder:text-Neutral500"
                onClick={() => setDomainModalOpen(!domainModalOpen)}
              >
                {doaminDetails?.domain}
              </button>
              <Chip
                isCustom
                status={
                  customEmailData?.isvarifyDomain ? "Verified" : "Pending"
                }
              />
            </div>
          )} */}
        </div>
      )}
    </>
  );

  return (
    <section className="w-full max-w-[830px] rounded-sm border border-Neutral300 p-4 md:p-7 bg-Neutral000">
      <div className={`flex items-center justify-between mb-10`}>
        <h3 className="text-xs font-bold tracking-widest uppercase text-Neutral600">
          Custom Email Address
        </h3>

        <div className="flex items-center gap-5">
          {customEmailData?.data?.sender_name ||
          customEmailData?.data?.reply_to_mail ||
          customEmailData?.data?.smtp_mail ? (
            <div>
              <button
                data-for="reset"
                data-tip="Not working? Reset to noreply@clientmanager.io or contact support"
                className="px-3 py-1 text-sm rounded-lg text-Neutral300 bg-AccentRegular"
                onClick={handleReset}
              >
                Reset To Default
              </button>
              <ReactTooltip
                place="bottom"
                textColor="#F5F5F7"
                className="tooltip-font-size"
                backgroundColor="#292933"
                id="reset"
              />
            </div>
          ) : null}
          {/* UI-TODO: This should link to a document which shows a guide on How to setup your own SMTP */}
          <Link
            to="https://www.clientmanager.io/smtp-settings"
            target="_blank"
            className="inline-flex items-center gap-1 text-xs font-medium text-AccentRegular"
          >
            <span>How to setup your own SMTP</span>
            <LinkIcon className="size-4" />
          </Link>
        </div>
      </div>
      {/* {doaminDetails?.domain && (
        <div className="flex justify-start w-full">
          <button
            type="button"
            className={`text-sm rounded my-4 h-10 px-4 py-1 w-fit transition duration-300 ease-in-out bg-AccentRegular text-white font-bold`}
            onClick={() => setDomainModalOpen(!domainModalOpen)}
          >
            Authenticate your domain
          </button>
        </div>
      )} */}
      <div className={`flex flex-col gap-6 ${domainDetails?.domain && "mt-2"}`}>
        {[
          {
            name: "sender_name",
            title: "Sender Name",
            type: "text",
            description:
              "Customise name of the email sender to something suitable for your company.",
            placeholder: "Luke Skywalker",
          },
          {
            name: "reply_to_mail",
            title: "Reply-To Email Address",
            type: "email",
            description:
              "Specifies the address to which replies to your emails will be sent.",
            placeholder: "lukeskywalker@gmail.com",
          },
          {
            name: "smtp_mail",
            title: "SMTP Settings",
            type: "email",
            description:
              "All client emails sent via ClientManager are being sent from the following address:",
            placeholder: "noreply@clientmanager.io",
          },
          // {
          //   title: "Domain Settings",
          //   description: "View and manage your domain configuration details.",
          //   name: "domain_details",
          // },
        ].map((field) => (
          <>
            <div
              key={field.name}
              className="flex flex-col justify-between gap-3 md:items-center md:flex-row md:gap-24"
            >
              <div className="space-y-1">
                <span className="flex items-center gap-2">
                  <h4 className="text-sm font-bold text-Neutral900">
                    {field.title}
                  </h4>
                  {field.name !== "sender_name" &&
                  (form.reply_to_mail || form.smtp_mail) ? (
                    <button
                      onClick={() => setIsReloadSite(true)}
                      className="text-blue-950"
                    >
                      &#8634;
                    </button>
                  ) : null}
                </span>

                <p className="text-sm text-Neutral700 max-w-[340px]">
                  {field.description}
                </p>
              </div>

              {renderInput(field.name, field.placeholder, field.type)}
            </div>
            {isError[field.name] && (
              <p className="text-xs text-red-500">{isError[field.name]}</p>
            )}
          </>
        ))}
      </div>

      <Modal
        isOpen={domainModalOpen}
        onRequestClose={() => setDomainModalOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="DKIM Records Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 max-w-[100%] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
            <div className="flex items-center justify-between pb-5 border-b">
              <span className="text-lg font-semibold text-neutral-700">
                {domainDetails?.domain}
              </span>
              <button
                type="button"
                disabled
                className={`ml-2 text-xs rounded px-2 py-1 ${
                  customEmailData?.isvarifyDomain
                    ? "bg-green-500"
                    : "bg-orange-500"
                } text-white`}
              >
                {customEmailData?.isvarifyDomain ? "Verified" : "Pending"}
              </button>
            </div>
            <br />
            <p className="mb-6 font-medium text-neutral-500">
              Copy the following DNS Records into your hosting provider. If you
              have questions please refer to our{" "}
              <Link to={""} className="text-blue-400">
                documentation
              </Link>
              .
            </p>
            <table className="min-w-full overflow-x-auto bg-white border shadow-sm border-Neutral400">
              <thead className="bg-[#F5F5F7] text-neutral-700">
                <tr>
                  <th className="w-1/12 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Dns Type
                  </th>
                  <th className="w-1/2 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Name
                  </th>
                  <th className="w-1/2 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {domainDetails?.dkimRecords?.map((record, index) => {
                  return (
                    <tr key={index} className={`border-b `}>
                      <td className="w-1/12 px-4 py-3 text-base font-medium text-left border text-neutral-800">
                        {record?.type}
                      </td>
                      <td className="w-1/2 px-4 py-3 text-left border">
                        <div className="flex items-center">
                          <span
                            className={`truncate text-base  ${
                              copyName[index] === "Copied"
                                ? "text-neutral-950"
                                : "text-neutral-800"
                            }`}
                          >
                            {record?.name}
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              handleCopy(record?.name, index, "name")
                            }
                            className={`ml-2 text-center ${
                              copyName[index] === "Copied"
                                ? "text-neutral-950"
                                : "text-Neutral700"
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              class="size-5"
                            >
                              <path
                                strokelinecapround="round"
                                strokeLinejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                      <td className="w-1/2 px-4 py-3 text-left border">
                        <div className="flex items-center">
                          <span
                            className={`truncate text-base ${
                              copyValue[index] === "Copied"
                                ? "text-neutral-950"
                                : "text-neutral-800"
                            }`}
                          >
                            {record?.value}
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              handleCopy(record?.value, index, "value")
                            }
                            className={`ml-2 ${
                              copyValue[index] === "Copied"
                                ? "text-neutral-900"
                                : "text-Neutral700"
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              class="size-5"
                            >
                              <path
                                strokelinecapround="round"
                                strokeLinejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setDomainModalOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <FeaturesModal
        isOpen={showUpgradeModal}
        setIsOpen={setShowUpgradeModal}
        data={{
          title: "Customise what your clients see...",
          features: [
            'Remove "Made with ClientManager"',
            "Use your company logo and domain",
            "Modify background & button colours",
            "And much more...",
          ],
          onViewPlanClick: () => {
            navigate("/white-label/plans");
          },
        }}
      />
    </section>
  );
}
export default CustomEmailCard;
