import { useSelector } from "react-redux";

function useBaseDomainURL() {
  const loggedInUser = useSelector((state) => state.auth.user);

  if (!loggedInUser) {
    // Return the current location origin if no user is logged in
    return window.location.origin;
  }

  if (loggedInUser.brandIdentity?.status === "active") {
    // Check if the user has a custom domain
    if (
      loggedInUser.brandIdentity?.domain === "custom" &&
      loggedInUser?.brandIdentity?.customDomain &&
      loggedInUser?.brandIdentity.customDomain.isDomainPropogated
    ) {
      return `https://${loggedInUser.brandIdentity.customDomain.custom_domain_name}`;
    }

    // Check if the user has a subdomain
    if (
      loggedInUser.subDomain?.subdomain &&
      loggedInUser.brandIdentity?.domain === "prefix"
    ) {
      return `${loggedInUser.subDomain.subdomain}.clientmanager.io`;
    }
  }

  // Fallback to the current window's location origin
  return window?.location?.origin;
}

export default useBaseDomainURL;
