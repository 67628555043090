import axios from "axios";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import Download from "../../assets/Images/Download-white.svg";
import { PaypalIcon } from "../../components/CreateInvoiceForm/PaypalIcon";
import { StripeIcon } from "../../components/CreateInvoiceForm/StripeIcon";
import useGetBrandIdentityForPublicRoute from "../../hooks/useGetBrandIdentityForPublicRoute";
import {
  ChangeInvoiceStatusViewedAction,
  GetPublicInvoiceByClientId,
} from "../../store/actions/invoicesAction";
import Logo from "../../assets/Images/Logo.svg";

const PublicInvoiceBuilder = () => {
  const { clientId, invoiceId } = useParams();
  const dispatch = useDispatch();
  const { brandIdentity } = useGetBrandIdentityForPublicRoute(clientId);

  const loggedInUser = useSelector((state) => state.auth.user);

  const [invoice, setInvoice] = useState({});
  const [brandData, setBrandData] = useState(null);
  const [customDomain, setCustomDomain] = useState(null);

  const companyName = brandIdentity?.companyName;
  const isWhiteLabelUser = brandIdentity?.status === "active";
  const isCustom = !!brandIdentity?.logo;

  const userDateFormat = loggedInUser?.date_format || "DD/MM/YYYY";
  const words = customDomain?.custom_domain_name.split("."); // ex.portal.chiragvaghasiya.dev
  const domainName = words && words[words?.length - 2]; // chiragvaghasiya

  const handleDownloadInvoice = async () => {
    const filename = `${invoice?.invoiceName}-${invoice?.invoiceNumber}-${invoice?.clientName}-${invoice?.invoiceDate}.pdf`;

    const contentWidthInInches = 620 / 96;
    const pageWidthInInches = 8.5;
    const sideMargin = (pageWidthInInches - contentWidthInInches) / 3;

    let elem = document.getElementById("invoice");
    var newElem = elem.cloneNode(true);

    const height = Math.ceil(elem.scrollHeight / 1000);

    newElem.setAttribute("id", "download-invoice");
    newElem.style.height = `${height * 1000}px`;

    // Select all #section but not select the last section.
    const sections = newElem.querySelectorAll("#section");
    const allButLast = Array.from(sections).slice(0, -1);
    allButLast.forEach((el) => {
      el.style.marginTop = "50px";
    });

    if (invoice?.paymentLink && invoice?.paymentMethod !== "Bank") {
      if (newElem.querySelectorAll("#paypalicon")?.length) {
        newElem.querySelectorAll("#paypalicon")[0].style.paddingTop = "1.25rem";
      }
    }
    if (newElem.querySelector("#cm_logo")) {
      newElem.querySelector("#cm_logo").style.marginTop = "10px";
    }

    // if (brandData && customDomain) {
    async function getBase64Image(url) {
      const data = await axios(url);
      return data.data.data;
    }

    let element = newElem.querySelector("#cm_logo");
    if (element && element.currentSrc.startsWith("http")) {
      const fileName = element.currentSrc.split(
        process.env.REACT_APP_BUCKET_NAME
      )[1];
      newElem.querySelector("#cm_logo").src = await getBase64Image(
        `/api/v1/invoice/get-invoice-base64?filename=${fileName}`
      );
    }
    // }
    console.log("newElem", newElem);
    html2pdf()
      .from(newElem)
      .set({
        margin: [0.5, sideMargin, 0.5, sideMargin],
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      })
      .toPdf()
      .get("pdf")
      .save(filename)
      .catch((err) => console.log("Error:", err));
  };

  useEffect(() => {
    const getServerProposalContent = async () => {
      let serverContent = null;

      if (clientId) {
        serverContent = await dispatch(
          GetPublicInvoiceByClientId(clientId, invoiceId)
        );
      }
      if (serverContent?.brandData) {
        setBrandData(serverContent?.brandData);
      }

      if (serverContent?.customDomain) {
        setCustomDomain(serverContent?.customDomain);
      }

      if (serverContent?.data) {
        if (
          serverContent?.data?.status === "draft" &&
          !localStorage.getItem("jwt_access_token")
        ) {
          await dispatch(
            ChangeInvoiceStatusViewedAction({
              id: invoiceId,
              status: "viewed",
            })
          );

          return setInvoice({
            ...serverContent?.data,
            status: "viewed",
          });
        }

        setInvoice(serverContent?.data);
      }
    };
    getServerProposalContent();
  }, [clientId, invoiceId, dispatch]);

  const subtotal = invoice?.services?.reduce((acc, service) => {
    if (service?.serviceType === "Fixed") {
      return acc + Number(service?.servicePrice);
    } else {
      return acc + Number(service?.quantity) * Number(service?.servicePrice);
    }
  }, 0);

  function roundToTwoDecimals(value) {
    if (isNaN(value)) return 0;
    return Math.round(value * 100) / 100;
  }

  const getDiscount = (subtotal, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount);
    if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
    return roundToTwoDecimals((subtotalNum * discountNum) / 100);
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const vatPercentageNum = Number(vatPercentage);
    if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum))
      return 0;
    const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
    return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
  };

  const getTotalDue = (subtotal, discount, vatPercentage, taxes, isVAT) => {
    const subTotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount) || 0;
    const vatPercentageNum = Number(vatPercentage) || 0;

    const taxesValid = Array.isArray(taxes) && taxes.length > 0;

    if (isVAT) {
      return roundToTwoDecimals(
        subTotalNum -
          getDiscount(subTotalNum, discountNum) +
          getVat(subTotalNum, vatPercentageNum, discountNum)
      );
    } else if (taxesValid) {
      const totalTaxes = taxes.reduce((total, tax) => {
        const taxValueNum = Number(tax.taxValue) || 0;
        return total + getVat(subTotalNum, taxValueNum, discountNum);
      }, 0);
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes
      );
    } else {
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum)
      );
    }
  };

  function formatDate(dateString) {
    if (!dateString) return "";
    return moment(dateString).format(userDateFormat);
  }

  return (
    <div className="flex flex-col mt-[40px]">
      <div className="flex flex-col justify-end align-center mb-[40px]">
        <div className="flex justify-center align-center">
          <div className="button-hover text-white flex flex-row font-bold text-sm right-11 w-[400px] md:w-[790px] 5xl:h-14 h-12 bg-AccentRegular items-center justify-center rounded mt-[16px] py-2 px-4 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300">
            <button
              className="flex items-center justify-center w-full"
              type="button"
              onClick={handleDownloadInvoice}
            >
              <span>Download PDF</span>
              <img className="ml-1" src={Download} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col gap-10 w-[620px] bg-white border-[1px] border-Neutral200 shadow-2xl p-8 mx-auto min-h-[1000px] h-max-content"
        id="invoice"
      >
        {/* SECTION 1 */}
        <div className="flex flex-row items-start justify-between">
          <h1 className="flex w-full text-2xl font-bold text-Neutral900">
            <span>INVOICE #{invoice?.invoiceNumber}</span>
          </h1>

          <div className="flex flex-row items-start justify-between">
            <div className="flex flex-col float-right gap-1 text-left">
              {invoice?.logo && (
                <img src={invoice?.logo} alt="" className="self-end w-24" />
              )}

              <h5 className="text-sm font-semibold uppercase text-Neutral800">
                {invoice?.fullName}
              </h5>
              <div className="text-sm text-zinc-800">
                {invoice?.companyName}
              </div>
              <div className="text-sm text-Neutral800">{invoice?.email}</div>
              <div className="text-sm text-Neutral800">
                {invoice.phoneNumber}
              </div>
              <div className="text-sm text-Neutral800">{invoice.address}</div>
              <div className="text-sm text-Neutral800">{invoice.VAT}</div>
            </div>
          </div>
        </div>

        {/* SECTION 2 */}
        <div
          className="flex flex-row items-center justify-between"
          id="section"
        >
          <div className="flex flex-col w-full gap-1">
            <h5 className="text-sm font-semibold uppercase text-Neutral800">
              Bill To
            </h5>
            <div className="text-sm font-semibold text-Neutral800">
              {invoice?.clientCompanyName}
            </div>
            <div className="text-sm font-semibold text-Neutral800">
              {invoice?.clientName}
            </div>
            <div className="text-sm text-Neutral800">
              {invoice?.clientAddress}
            </div>
            <div className="text-sm text-Neutral800">
              {invoice?.clientEmail}
            </div>
            <div className="text-sm text-Neutral800">
              {invoice?.clientPhoneNumber}
            </div>
            <div className="text-sm text-Neutral800">{invoice?.clientVAT}</div>
          </div>
          <div className="flex flex-col w-full gap-1 text-right">
            <h5 className="text-sm font-semibold uppercase text-Neutral800">
              Invoice Details
            </h5>
            <div className="text-sm text-Neutral500">Invoice Date</div>
            <div className="text-sm font-semibold text-Neutral800">
              {formatDate(invoice?.invoiceDate)}
            </div>
            <div className="text-sm text-Neutral500">Due Date</div>
            <div className="text-sm font-semibold text-Neutral800">
              {formatDate(invoice?.dueDate)}
            </div>
          </div>
        </div>

        {/* SECTION 3 */}
        <div className="flex flex-col gap-3">
          <div className="relative">
            <table
              className="w-full text-sm text-left text-gray-500"
              id="section"
            >
              <thead className="text-xs font-normal text-gray-400">
                <tr>
                  <th scope="col" className="w-2/5 py-2">
                    Description
                  </th>
                  {invoice?.services?.some(
                    (service) => service?.serviceType === "Hourly"
                  ) && (
                    <th scope="col" className="w-1/5 py-2 text-left">
                      Hours
                    </th>
                  )}

                  <th scope="col" className="w-1/5 py-2 text-left">
                    {invoice?.services?.some(
                      (service) => service?.serviceType === "Hourly"
                    ) && <>Price/HR</>}
                  </th>

                  <th scope="col" className="w-1/5 py-2 text-left">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice?.services?.map((service, i) => (
                  <tr className="bg-white border-b" key={i}>
                    <td
                      scope="row"
                      className="w-3/5 py-2 pr-4 font-medium text-gray-900 break-all"
                    >
                      {service?.serviceName}
                    </td>
                    <td className="py-2 text-left">
                      {service?.serviceType !== "Fixed" && (
                        <>{service?.quantity}</>
                      )}
                    </td>
                    <td className="py-2 text-left">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: invoice?.currency || "USD",
                      }).format(service?.servicePrice)}
                    </td>
                    <td className="py-2 text-right">
                      {service.serviceType === "Fixed" ? (
                        <>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(service?.servicePrice)}
                        </>
                      ) : (
                        <>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(service?.quantity * service?.servicePrice)}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                <tr className="bg-white">
                  <th
                    scope="row"
                    className="py-2 font-medium text-gray-900 whitespace-nowrap"
                  ></th>
                  <td className="py-2 text-left"></td>
                  <td className="py-2 text-left">Subtotal</td>
                  <td className="py-2 text-right">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: invoice?.currency || "USD",
                    }).format(subtotal)}
                  </td>
                </tr>
                {invoice?.discount > 0 && (
                  <tr className="bg-white">
                    <th
                      scope="row"
                      className="py-2 font-medium text-gray-900 whitespace-nowrap"
                    ></th>
                    <td className="py-2 text-left"></td>
                    <td className="py-2 text-left">
                      Discount ({invoice?.discount}%)
                    </td>
                    <td className="py-2 text-right">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: invoice?.currency || "USD",
                      }).format(getDiscount(subtotal, invoice?.discount))}
                    </td>
                  </tr>
                )}
                {(invoice?.is_VAT && (
                  <tr className="bg-white">
                    <th
                      scope="row"
                      className="py-2 font-medium text-gray-900 whitespace-nowrap"
                    ></th>
                    <td className="py-2 text-left"></td>
                    <td className="py-2 text-left">
                      VAT ({invoice?.vatPercentage}%)
                    </td>
                    <td className="py-2 text-right">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: invoice?.currency || "USD",
                      }).format(
                        getVat(
                          subtotal,
                          invoice?.vatPercentage,
                          invoice?.discount
                        )
                      )}
                    </td>
                  </tr>
                )) ||
                  invoice.taxes?.map((tax, i) => (
                    <tr className="bg-white">
                      <th
                        scope="row"
                        className="py-2 font-medium text-gray-900 whitespace-nowrap"
                      ></th>
                      <td className="py-2 text-left"></td>
                      <td className="py-2 text-left">
                        {tax.taxName} ({tax?.taxValue}%)
                      </td>
                      <td className="py-2 text-right">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: invoice?.currency || "USD",
                        }).format(
                          getVat(subtotal, tax?.taxValue, invoice?.discount)
                        )}
                      </td>
                    </tr>
                  ))}
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="py-2 font-medium text-gray-900 whitespace-nowrap"
                  ></th>
                  <td className="py-2 text-left"></td>
                  <td className="py-2 font-semibold text-left uppercase text-zinc-800">
                    Total Due
                  </td>
                  <td className="py-2 font-semibold text-right uppercase text-zinc-800">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: invoice?.currency || "USD",
                    }).format(
                      getTotalDue(
                        subtotal,
                        invoice?.discount,
                        invoice?.vatPercentage,
                        invoice?.taxes,
                        invoice?.is_VAT
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="section">
            {invoice.paymentLink && invoice.paymentMethod === "Paypal" && (
              <Link
                to={invoice.paymentLink}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end gap-2 cursor-pointer"
              >
                <span className="text-sm font-semibold underline text-zinc-800 ">
                  Click here to pay with
                </span>
                <span
                  className="text-sm font-semibold text-zinc-800"
                  id="paypalicon"
                >
                  <PaypalIcon />
                </span>
              </Link>
            )}
            {invoice.paymentLink && invoice.paymentMethod === "Stripe" && (
              <Link
                to={invoice.paymentLink}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end gap-2 cursor-pointer"
              >
                <span className="text-sm font-semibold underline text-zinc-800 ">
                  Click here to pay with
                </span>
                <span
                  className="text-sm font-semibold text-zinc-800"
                  id="paypalicon"
                >
                  <StripeIcon />
                </span>
              </Link>
            )}
            {invoice.paymentMethod === "Bank" && invoice?.bankDetails && (
              <div className="flex flex-col w-full gap-2 p-4 mt-3 border border-Neutral200 ">
                <h5 className="text-sm font-semibold uppercase text-Neutral800">
                  Bank Details
                </h5>
                <div className="text-sm whitespace-pre-wrap text-Neutral700">
                  {invoice.bankDetails}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* SECTION 4 */}
        <div className="bg-Neutral000 w-full max-w-[788px] mx-auto mt-auto flex items-center">
          {(() => {
            if (isCustom && companyName) {
              // Case 1: Custom logo and company name
              return (
                <div className="flex items-center justify-center flex-grow">
                  <a
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    id="logo_div"
                    className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                  >
                    <img
                      src={brandIdentity?.logo}
                      alt={`${companyName} Logo`}
                      id="cm_logo"
                      className="mr-[6px] h-5 w-5"
                    />
                    Made with
                    <span className="text-gray-500 font-bold ml-[2px]">
                      {companyName}
                    </span>
                  </a>
                </div>
              );
            } else if (isCustom && !companyName) {
              // Case 2: Custom logo only
              return (
                <div className="flex items-center justify-center flex-grow">
                  <img
                    src={brandIdentity?.logo}
                    alt="Company Logo"
                    id="cm_logo"
                    className="w-5 h-5"
                  />
                </div>
              );
            } else if (!isCustom && !isWhiteLabelUser) {
              // Case 3: Default branding for normal user
              return (
                <div className="flex items-center justify-center flex-grow">
                  <a
                    href="https://www.clientmanager.io/"
                    target="_blank"
                    rel="noreferrer"
                    id="logo_div"
                    className="text-Neutral700 text-[10px] font-normal leading-[7px] flex items-center"
                  >
                    <img
                      src={Logo}
                      alt="ClientManager Logo"
                      id="cm_logo"
                      className="mr-[6px] h-5 w-5"
                    />
                    Made with
                    <span className="text-gray-500 font-bold ml-[2px]">
                      ClientManager
                    </span>
                  </a>
                </div>
              );
            } else if (!isCustom && isWhiteLabelUser) {
              // Case 4: No branding (blank)
              return null;
            } else {
              // Optional: Handle any unexpected cases
              return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default PublicInvoiceBuilder;
