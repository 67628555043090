import axios from "axios";
import { toast } from "react-toastify";
axios.defaults.headers["user_type"] = "user";

export const updateCustomEmailDetails = (
  values,
  setIsLoading,
  setFocusedInput,
  setIsError
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      let { data } = await axios.post(
        "api/v1/custom-email/update-custom-email-details",
        { ...values }
      );
      setFocusedInput("");
      toast.success("Custom email details updated successfully");
      setIsLoading(false);
      return dispatch({
        type: "UPDATE_CUSTOM_EMAIL",
        payload: { data: data?.data },
      });
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 422) {
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element?.param] = element?.msg;
        });
        setIsError(err);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
};

export const resetCustomEmailData = (userId) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/custom-email/reset-custom-email-details",
        userId
      );
      toast.success(data?.message);
      return dispatch({
        type: "RESET_EMAIL_DETAILS",
        payload: {
          data: null,
          isVerifyEmail: { email: null, verified: false },
          isvarifyDomain: false,
        },
      });
    } catch (error) {
      toast.error("Someting went wrong while reset the email details");
    }
  };
};

export const getCustomEmailDetails = (userId, setIsReloadSite) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/custom-email/get-custom-email-details",
        { userId }
      );
      setIsReloadSite(false);
      return dispatch({
        type: "GET_CUSTOM_EMAIL",
        payload: { data: data.data },
      });
    } catch (error) {
      setIsReloadSite(false);
      console.log("GET_CUSTOM_EMAIL_ERROR :", error);
    }
  };
};

export const verifyEmailAction = (value) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/custom-email/verify-email",
        value
      );
      if (data) {
        dispatch(veficationStatusCheck(value));
      }
      return dispatch({
        type: "IS_VERIFY_EMAIL_ADDRESS",
        payload: { data: data },
      });
    } catch (error) {
      console.log("Error in verify email :", error);
    }
  };
};

export const veficationStatusCheck = (value) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/custom-email/get-verification-status",
        value
      );
      return dispatch({
        type: "IS_VERIFY_EMAIL_ADDRESS",
        payload: {
          data: data?.data,
        },
      });
    } catch (error) {
      console.log("Error in vefication Status Check ", error);
    }
  };
};